<template>
	<div class="mainTem">
		<el-form ref="form" :disabled="hasOnlyView" :rules="formRules" :model="form" label-width="150px" style="min-width: 1100px;width: 100%;" v-loading="loading">
			<el-card>
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<h3>{{ $t('Storage.packing_list.basic_information') }}</h3>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_c944a6686d996ab3')" required>
								<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
							</el-form-item>
					<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="">
						<el-input type="text" v-model="form.title" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
					</el-form-item>

					<el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
						<HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
					</el-form-item>
				</div>
			</el-card>

			<el-card v-if="hasEdit || fileList.length>0">
				<div slot="header">
					<h3>{{ $t('i18nn_8200a25ab641ba97') }}</h3>
				</div>
				<div v-if="hasEdit">
					<HyElWhUpLoad
						ref="HyElWhUpLoad"
						:auto_upload="true"
						:listType="'picture'"
						:showFileList="false"
						:watchKey="fileWatchKey"
						:fileListShow="fileList"
						:fileId="fileId"
						:folder="'workOrder/'"
						:accept="''"
						:maxSizeMB="50"
						:limit="20"
						v-on:UploadSuccessUrl="changeFileUrl"
					></HyElWhUpLoad>
				</div>
				<div v-else>
					<el-table ref="multipleTable" :data="fileList" stripe :border="true" style="width: 100%" size="small">
					  <el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
					  
						<!-- <el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')" width="100"></el-table-column> -->
						
						<el-table-column prop="name" :label="$t('i18nn_1aa9b398f37906a4')"></el-table-column>
						
					  <el-table-column prop="url" :label="$t('i18nn_d54012286fece209')">
					    <template slot-scope="scope">
					      <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
					    </template>
					  </el-table-column>
					</el-table>
				</div>
			</el-card>

			<el-card>
				<div slot="header">
					<h3>{{ $t('Storage.packing_list.Other') }}</h3>
				</div>
				<div>
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit ></el-input>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div v-if="!hasOnlyView">
			<!-- <div style="margin: 10px;" v-if="hasEdit">
				<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
			</div> -->
			<div style="margin: 10px;">
				<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			</div>
		</div>
		
	</div>
</template>
<script>
import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	// },

	// props: {
	//   // mobile:"",
	//   isSel: {
	//     default: function() {
	//       return false;
	//     },
	//     type: Boolean
	//   },
	//   status: {
	//     default: function() {
	//       return '';
	//     },
	//     type: String
	//   }
	// },
	components: {
		whNoSelect,
		HyElWhUpLoad,
		HyQuillEditor
	},
	data() {
		return {
			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改

			loading: false,

			loading_det: false,
			//是否可编辑
			hasEdit:false,
			//是否仅预览
			hasOnlyView: false,
			//附件
			fileWatchKey: '1',
			fileList: [],
			fileId: this.$store.getters.getUserInfo.id,

			form: {
				id: '', //"数据ID",
				whNo:'',
				title: '', //this.$t('i18nn_7431e76029678ec7'),
				orderType: '10', //this.$t('i18nn_f566f50ef680920c'),
				contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
				attachments: [
					// {
					// 	id: '', //"数据ID",
					// 	url: '', //"文件URL",
					// 	fileName: '' //this.$t('i18nn_89221ad15d2ec113')
					// }
				]
			},

			formRules: {
				title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
			},
			// loading_load: false,

			selectOption: {
				wh_work_order_type: []
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
		this.initData();
		
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		
		
	},
	methods: {
		initData() {
			if(this.$route.query.id){
				//编辑
				this.hasEdit = true;
				this.getPageDetData(this.$route.query.id);
			} else {
				//新增
				this.hasEdit = false;
			}
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.form.whNo = data.code;
			// this.filterData.userId = data.userId;
			// this.initData();
		},
		// changeAgentUser(val){
		// 	// this.filterData.agentUser = val;
		// 	// this.initData();
		// },
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		// openDioalog(formParm, msg) {
		// 	// console.log(formParm);
		// 	this.dialogFormVisible = true;
		// 	let form = Object.assign({}, formParm);
		// 	console.log('form', form);
		// 	this.dialogFormMsg = msg;
		// 	// // 重置
		// 	this.resetForm('form');
		// 	if (null === formParm) {
		// 		//新增

		// 		// this.form.weightUnit = '1';
		// 		// this.form.volumeUnit = '1';
		// 		// this.form.whFeeType = '1';

		// 		this.dialogFormStatus = 0;
		// 		// form.subUserId = null;
		// 		// form.userSubUserId = null;
		// 		// form.state = true;
		// 		//浅拷贝、对象属性的合并
		// 		this.form = form;
		// 	} else {
		// 		//修改
		// 		this.dialogFormStatus = 1;
		// 		// form.state = form.state === '0' ? true : false;

		// 		//浅拷贝、对象属性的合并
		// 		this.form = form;
		// 	}

		// 	this.$forceUpdate();
		// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
		// 	//       type: 'warning',
		// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 	//     });
		// },
		// 富文本
		onEditorChange(html) {
			this.form.contentStr = html;
		},
		//打开编辑
		// openEdit(event, row, index) {
		// 	event.stopPropagation();
		// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
		// 	this.getPageDetData(row.id);
		// },
		//明细
		//请求分页数据
		getPageDetData(id) {
			// let _this = this;
			this.loading = true;

			this.$http
				.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading = false;
					//是否可编辑
					if('0'==data.data.status){
						this.hasEdit = true;
					}
					
					//表格显示数据
					this.form = data.data;
					//是否仅仅预览
					if(null!=this.form.status &&''!=this.form.status && '0'!=this.form.status){
						this.hasOnlyView = true;
					}
					
					this.$nextTick(()=>{
						this.$refs.whNoSelect.init(this.form.whNo);
					})

					this.fileList = data.data.attachments.map(item => {
						return {
							id: item.id,
							name: item.fileName,
							url: item.url
						};
					});
					this.fileWatchKey = new Date().getTime();

				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading = false;
				});
		},

		//上传回调
		changeFileUrl(val) {
			console.log('changeFileUrl', val);
			let listUrl = val.map(item => {
				// "filePath":"","fileName"：“”
				return {
					url: item.url,
					fileName: item.name
				};
			});

			this.form.attachments = listUrl;
		},

		//提交信息
		submitForm(formName) {
			this.$nextTick(() => {
				try {
					this.$refs.HyElWhUpLoad.submitUpload();
				} catch (err) {
					console.log(err);
				}
			});

			this.$refs[formName].validate(valid => {
				if (valid) {
					let formData = Object.assign({}, this.form);
					//浅拷贝、对象属性的合并
					// if (formData.id = null) {
					// formData.id = null;
					// formData.userId = this.UserInfo.id;

					this.postData(this.$urlConfig.WhWorkOrderAdd, formData, () => {
						this.$emit('submitSuccess');
					});
					// } else {
					// 	formData.userId = this.UserInfo.id;
					// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
					// }
				} else {
					console.log('error submit!!');
					this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
					return false;
				}
			});
		},
		//重置输入框
		resetForm(formName) {
			console.log(formName);
			// console.log(this.$refs[formName]);

			this.$nextTick(() => {
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// if ('form' === formName) {
					// 	this[formName]['packRecordList'] = [];
					// }
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			});
			this.$nextTick(() => {
				try {
					this.$refs.HyElWhUpLoad.initData();
					this.$refs.HyElWhUpLoad.clearFileList();
				} catch (err) {
					console.log(err);
				}
			});
			this.fileList = [];
		},

		//提交信息
		postData(url, formData, callback) {
			// HttpTypelet _this = this;
			this.loading = true;
			// this.loading_load = true;
			let HttpType = {};
			// if ('delete' == type) {
			// 	HttpType = this.$http.delete(url, formData);
			// } else {
			HttpType = this.$http.put(url, formData);
			// }
			HttpType.then(({ data }) => {
				console.log(this.$t('i18nn_bc868e024b80d2e3'));
				console.log(data);
				
				this.loading = false;
				// this.loading_load = false;
				if (200 == data.code) {
					// this.dialogFormVisible = false;
					// this.getPageData();

					// if ('delete' == type) {
					// 	this.$message({
					// 		type: 'success',
					// 		message: this.$t('tips.successfullyDelete')
					// 	});
					// } else {
					this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						type: 'success'
					});
					callback();
					// }
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				// this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				});
			});
		},

		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);
		// 	// this.loading_load = true;
		// 	// 	this.$http
		// 	// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
		// 	// 		.then(({ data }) => {
		// 	// 			console.log('查询数据字典，请求成功');
		// 	// 			console.log(data);
		// 	// 			if (200 == data.code && data.data) {
		// 	// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 	// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 	// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 	// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
		// 	// 				// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
		// 	// 			} else {
		// 	// 				if (!data.msg) {
		// 	// 					data.msg = this.$t('tips.submitError');
		// 	// 				}
		// 	// 				this.$message.warning(data.msg);
		// 	// 			}
		// 	// 		})
		// 	// 		.catch(error => {
		// 	// 			console.log(error);
		// 	// 			console.log('查询数据字典接口，请求失败');
		// 	// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 	// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
